import type { Keyword } from '@prisma/client';
import type { SerializeFrom } from '@remix-run/node';

import { cn } from '#app/utils/misc';
import { NavLink } from '@remix-run/react';
import React from 'react';

import { Icon } from './ui/icon';

interface Props {
  keyword: SerializeFrom<Keyword>;
  width: number;
}
const RelateKeyword:React.FC<Props> = (props) => {

  return (
    <NavLink reloadDocument to={`/keyword-library/${props.keyword.name.replaceAll('/', '-')}`}>
      <article className={
        cn(
          'grid grid-flow-col gap-1 justify-center',
          'py-[0.8125rem] h-10 max-w-[11.25rem] rounded-xl',
          'hover:cursor-pointer hover:bg-gray-20',
          'shadow-[0px_4px_18px_0px_rgba(0,0,0,0.12)]',
        )
      } style={{
        width: props.width,
      }}>
        <Icon name='regular-hashtag' /> <span className='text-gray-90 text-caption-1-medium xs:font-sfProMedium sm:font-pretendardMedium'>{props.keyword.name}</span>
      </article>
    </NavLink>
  );
};

export default RelateKeyword;
